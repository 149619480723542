export const initialUserState = {
  id: 0,
  name: '',
  surname: '',
  username: '',
  email: '',
  role: 0,
  balance: 0,
  bonusBalance: 0,
  currency: '',
  parentAgentId: 0,
  structurePath: '',
  note: '',
  isBanned: false,
  isVisible: true,
  isWithdrawalAccess: true,
  isDepositAccess: true,
  firstVisit: '',
  lastVisit: '',
  structure: '',
  lastUsersSearch: '',
};

export const initialState = {
  actionProcessing: false,
  usersList: {
    balanceSum: 0,
    totalCount: 0,
    users: [],
  },
  userInfo: initialUserState,
  usersInfo: null,
  usersTreeData: null,
  isNeedToRefresh: false,
  bannedProviders: {
    blocklist: [],
    lockData: [],
  },
  userPermissions: {
    slots: {
      locale: 'slots',
      isActive: false,
      isOpen: false,
      changeSubstructure: false,
      providers: [
        { isActive: false, changeSubstructure: false, provider: 'softgaming-slots' },
        { isActive: false, changeSubstructure: false, provider: 'pragmatic' },
        { isActive: false, changeSubstructure: false, provider: 'outcomebet' },
        { isActive: false, changeSubstructure: false, provider: 'fiable' },
        { isActive: false, changeSubstructure: false, provider: 'evoplay' },
        { isActive: false, changeSubstructure: false, provider: 'rubyplay' },
        { isActive: false, changeSubstructure: false, provider: 'slotegrator' },
        { isActive: false, changeSubstructure: false, provider: 'casimi' },
        { isActive: false, changeSubstructure: false, provider: 'inbet' },
      ],
    },
    'live-casino': {
      locale: 'liveCasino',
      isActive: false,
      changeSubstructure: false,
      isOpen: false,
      providers: [
        { isActive: false, changeSubstructure: false, provider: 'xpg' },
        { isActive: false, changeSubstructure: false, provider: 'tvbet' },
        { isActive: false, changeSubstructure: false, provider: 'pragmatic' },
        { isActive: false, changeSubstructure: false, provider: 'popokgaming' },
      ],
    },
    'virtual-games': {
      locale: 'virtualGames',
      isActive: false,
      changeSubstructure: false,
      isOpen: false,
      providers: [{ isActive: false, changeSubstructure: false, provider: 'goldenrace' }],
    },
    sport: {
      locale: 'sport',
      isActive: false,
      changeSubstructure: false,
      isOpen: false,
      providers: [{ isActive: false, changeSubstructure: false, provider: 'DIGITAIN' }],
    },
  },
};
